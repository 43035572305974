import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    register_dualpass = "register_dualpass",
        Label1 = "Label1",
        Label11 = "Label11",
        Combo_box12 = "Combo_box12",
        Label3 = "Label3",
        Email = "Email",
        Label5 = "Label5",
        Serial = "Serial",
        btn_usgetlicense = "btn_usgetlicense",
        btn_usgetsupport = "btn_usgetsupport",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get Combo_box12(): FormControl {
        return this.fg.controls[MgControlName.Combo_box12] as FormControl;
    }

    get Email(): FormControl {
        return this.fg.controls[MgControlName.Email] as FormControl;
    }

    get Serial(): FormControl {
        return this.fg.controls[MgControlName.Serial] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}