
<div style="padding: 1%;"
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="divflex"
        [magic]="mgc.startprogram"
    >
        <div class="divflex">
            <router-outlet [magic]="mgc.MainOutlet">
            </router-outlet>
        </div>
    </div>
</div>
