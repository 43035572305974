import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Support = "Support",
        Label5 = "Label5",
        V_email = "V_email",
        Label6 = "Label6",
        Combo_box9 = "Combo_box9",
        Label7 = "Label7",
        V_question_topic = "V_question_topic",
        Label8 = "Label8",
        V_question_mesaage = "V_question_mesaage",
        Button10 = "Button10",
        btn_ussend = "btn_ussend"
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get V_email(): FormControl {
        return this.fg.controls[MgControlName.V_email] as FormControl;
    }

    get Combo_box9(): FormControl {
        return this.fg.controls[MgControlName.Combo_box9] as FormControl;
    }

    get V_question_topic(): FormControl {
        return this.fg.controls[MgControlName.V_question_topic] as FormControl;
    }

    get V_question_mesaage(): FormControl {
        return this.fg.controls[MgControlName.V_question_mesaage] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}
