<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.Support"
    >
          <div style="display: flex; flex-direction: row">
            <label>
                <h3>SmartPassport Dualpass Support Online</h3>
            </label>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label5"
                class="lable_overflow"
            >
                Email *:
            </label>
        </div>
        <div class="divflex">
            <div>
                <mat-form-field>
                    <div>
                        <input
                            matInput
                            [magic]="mgc.V_email"
                            [formControlName]="mgc.V_email"
                            mgFormat
                            required
                        >
                        <mgError [magic]=mgc.V_email> </mgError>
                    </div>
                </mat-form-field>
            </div>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label6"
                class="lable_overflow"
            >
                Question Type *:
            </label>
        </div>
        <div class="divflex">
            <div>
                <mat-form-field>
                    <mat-select
                        [magic]="mgc.Combo_box9"
                        [formControlName]="mgc.Combo_box9"
                        required
                    >
                        <mat-option
                            *ngFor="let o of mg.getItemListValues(mgc.Combo_box9);"
                            [value]="o.index"
                        >
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mgError [magic]=mgc.Combo_box9> </mgError>
            </div>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label7"
                class="lable_overflow"
            >
                Topic *:
            </label>
        </div>
        <div class="divflex">
            <div>
                <mat-form-field>
                    <div>
                        <input
                            matInput
                            [magic]="mgc.V_question_topic"
                            [formControlName]="mgc.V_question_topic"
                            mgFormat
                            required
                        >
                        <mgError [magic]=mgc.V_question_topic> </mgError>
                    </div>
                </mat-form-field>
            </div>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label8"
                class="lable_overflow"
            >
                Mesaage *:
            </label>
        </div>
        <div class="divflex">
            <div>
                <mat-form-field>
                    <div>
                        <textarea rows="5" input
                            matInput
                            [magic]="mgc.V_question_mesaage"
                            [formControlName]="mgc.V_question_mesaage"
                            mgFormat
                            required
                        ></textarea>
                        <mgError [magic]=mgc.V_question_mesaage> </mgError>
                    </div>
                </mat-form-field>
            </div>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.Button10"
            >
                Send
            </button>
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.btn_ussend"
                [style.visibility]="mg.getVisible(mgc.btn_ussend)"
            >
                View history
            </button>


        </div>
    </div>
</div>
