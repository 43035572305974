import { Component } from '@angular/core';

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName, MgCustomProperties } from "./History.mg.controls.g";
import { MgDisplayedColumns } from "./History.mg.controls.g";

import { BaseMatTableMagicComponent, matMagicProviders } from "@magic-xpa/angular-material-core";


import { MagicModalInterface } from "@magic-xpa/angular";

@Component({
    selector: 'mga-History',
    providers: [...matMagicProviders],
    templateUrl: './History.component.html'
})
export class History extends BaseMatTableMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgcp = MgCustomProperties;
    mgfc: MgFormControlsAccessor;
    mgdp = MgDisplayedColumns;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
    private static readonly formName: string = "History";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "300px";
    private static readonly height: string = "550px";
    private static readonly isCenteredToWindow: boolean = true;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return History.x;
    }
    Y() {
        return History.y;
    }
    Width(): string {
        return History.width;
    }
    Height(): string {
        return History.height;
    }
    IsCenteredToWindow() {
        return History.isCenteredToWindow;
    }
    FormName() {
        return History.formName;
    }
    ShowTitleBar() {
        return History.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return History.shouldCloseOnBackgroundClick;
    }
    displayedColumns = this.mgdp;
}
