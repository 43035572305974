import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    History = "History",
        Button19 = "Button19",
        Table1 = "Table1",
        Column13 = "Column13",
        Label20 = "Label20",
        Column17 = "Column17",
        Label21 = "Label21",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'Column13',
        'Column17',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}