<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.register_dualpass"
    >
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label1"
                class="lable_overflow"
            >
                <h3>SmartPassport Dualpass License Manager Online</h3>
            </label>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label11"
                class="lable_overflow"
            >
                Product Type *:
            </label>
        </div>
        <div class="divflex">
            <div>
                <mat-form-field>
                    <mat-select
                        [magic]="mgc.Combo_box12"
                        [formControlName]="mgc.Combo_box12"
                    >
                        <mat-option
                            *ngFor="let o of mg.getItemListValues(mgc.Combo_box12);"
                            [value]="o.index"
                        >
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label3"
                class="lable_overflow"
            >
                Email *:
            </label>
        </div>
        <div class="divflex">
            <div>
                <mat-form-field>
                    <div>
                        <input
                            matInput
                            [magic]="mgc.Email"
                            [formControlName]="mgc.Email"
                            mgFormat
                        >
                        <mgError [magic]=mgc.Email> </mgError>
                    </div>
                </mat-form-field>
            </div>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label5"
                class="lable_overflow"
            >
                Serial No. *:
            </label>
        </div>
        <div class="divflex">
            <div>
                <mat-form-field>
                    <div>
                        <input
                            matInput
                            [magic]="mgc.Serial"
                            [formControlName]="mgc.Serial"
                            mgFormat
                        >
                        <mgError [magic]=mgc.Serial> </mgError>
                    </div>
                </mat-form-field>
            </div>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.btn_usgetlicense"
            >
                Get a License
            </button>
            <button
            mat-raised-button
            color="primary"
            [magic]="mgc.btn_usgetsupport"
            >
            Get Support
            </button>
        </div>
    </div>
</div>
