<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.History"
    >
        <div style="display: flex; flex-direction: row">
            <div class="example-container mat-elevation-z8 Table1TableContainerProps">
                <mat-table
                    #table
                    [dataSource]="dataSource"
                    class="container_border"
                    matSort
                    matSortDisableClear
                    (matSortChange)="tableService.sortData($event)"
                >
                    <ng-container
                        [magic]="mgc.Column13"
                        [matColumnDef]="mgc.Column13"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            Question
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <label
                                    [magic]="mgc.Label20"
                                    [rowId]="row.rowId"
                                    class="lable_overflow"
                                >
                                    {{mg.getText(mgc.Label20, row.rowId)}}
                                </label>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column17"
                        [matColumnDef]="mgc.Column17"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            Answer
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <label
                                    [magic]="mgc.Label21"
                                    [rowId]="row.rowId"
                                    class="lable_overflow"
                                >
                                    {{mg.getText(mgc.Label21, row.rowId)}}
                                </label>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="table_row"
                        [ngClass]="{ 'selected': selection.isSelected(row)}"
                        [magicRow]="row?.rowId"
                        (click)="row? tableService.selectRow(row.rowId) : null"
                    >
                    </mat-row>
                </mat-table>
                <mat-paginator
                    #paginator
                    [pageSize]="1"
                    [pageSizeOptions]="[1, 2, 5, 10, 20]"
                    (page)="tableService.mgOnPaginateChange($event)"
                >
                </mat-paginator>
            </div>
        </div>
        <div style="display: flex; flex-direction: row">
          <button
              mat-raised-button
              color="primary"
              [magic]="mgc.Button19"
          >
              Back
          </button>
      </div>
    </div>
</div>
