<ng-template #mySpinner>
  <div>
    <img id="img-spin" src="http://ramanine.com/spindirectory.gif">
    <!--img id="img-spin" src="http://ramanine.com/spinvnursecare.gif"-->
    <!--div class="spinner"> </div-->
  </div>
</ng-template>

<div>
  <magic-root [SpinnerTemplate]="mySpinner"></magic-root>
</div>
