<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.License"
    >
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label1"
                class="lable_overflow"
            >
                Your license is:
            </label>
        </div><br>
        <div class="divflex">
            <div>
                <mat-form-field>
                    <div>
                        <textarea rows="13" input
                            matInput
                            [magic]="mgc.Edit2"
                            [formControlName]="mgc.Edit2"
                            mgFormat
                        ></textarea>
                        <mgError [magic]=mgc.Edit2> </mgError>
                    </div>
                </mat-form-field>
            </div>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label3"
                class="lable_overflow"
            >
                Please copy license then paste into Smartpassport register page.
            </label>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label4"
                class="lable_overflow"
            >
                Thank you for support us.
            </label>
        </div><br>
        <div style="display: flex; flex-direction: row">
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.Button5"
            >
                Close
            </button>
        </div>
    </div>
</div>
