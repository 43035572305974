import { startprogram as startprogram_startprogram } from './startprogram/startprogram.component';
import { register_dualpass as register_dualpass_register_dualpass } from './register_dualpass/register_dualpass.component';
import { License as register_dualpass_View_License_License } from './register_dualpass/View License/License.component';
import { Support as support_Support } from './support/Support.component';
import { History as support_History_History } from './support/History/History.component';

export const title = "";

export const magicGenCmpsHash = {               support_Support:support_Support,
              support_History_History:support_History_History,
                      startprogram_startprogram:startprogram_startprogram,
              register_dualpass_register_dualpass:register_dualpass_register_dualpass,
              register_dualpass_View_License_License:register_dualpass_View_License_License,

};

export const magicGenComponents = [ support_Support,
support_History_History ,  startprogram_startprogram,
register_dualpass_register_dualpass,
register_dualpass_View_License_License
];


export const LazyLoadModulesMap = {};
